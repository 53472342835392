import {
  ComponentStyleConfig,
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/react';
import { radioAnatomy as parts } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleContainer = defineStyle(() => ({
  '.chakra-radio': {
    display: 'unset',
  },
}));

const baseStyleControl = defineStyle(({ theme }) => {
  return {
    display: 'none',
    _focusVisible: {
      ...theme.styleTokens.focusVisible,
    },
    _invalid: {
      borderColor: 'error.600',
    },
    '& + .chakra-radio__label': {
      marginInlineStart: 0,
    },
  };
});

const baseStyleImageContainer = defineStyle(() => {
  return {
    border: '1px solid',
    borderColor: 'neutral.300',
    padding: '0.5rem',
    '[data-checked] > &': {
      borderColor: 'primary.600',
    },
    '[aria-invalid] > &': {
      borderColor: 'error.600',
    },
  };
});

const baseStyleLabel = defineStyle(({ theme }) => {
  return {
    ...theme.styleTokens.baseInputLabel,
    display: 'inline-block',
    marginTop: '0.5rem',
  };
});

const baseStyle = definePartsStyle((props) => {
  return {
    container: baseStyleContainer(),
    control: baseStyleControl(props),
    imageContainer: baseStyleImageContainer(),
    label: baseStyleLabel(props),
  };
});

export const imageRadioTheme: ComponentStyleConfig = defineMultiStyleConfig({
  baseStyle,
});
