import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const parts = ['file', 'files'];

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts);

const baseStyle = definePartsStyle(({ theme }) => {
  return {
    files: {
      alignSelf: 'stretch',
      listStyle: 'none',
      p: 0,
      m: 0,
      pb: 6,
    },
    file: {
      ...theme.styleTokens.defaultBorder,
      borderRadius: 'lg',
      listStyle: 'none',
      p: '6',
      pt: '3',
      pb: '3',
      m: 0,
      mt: '3',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      userSelect: 'none',
    },
  };
});

export const fileListTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {},
});
