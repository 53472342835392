import {
  ComponentStyleConfig,
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers([
    'container',
    'label',
    'labelContainer',
    'swatch',
  ]);

const baseStyleContainer = defineStyle(() => {
  return {
    borderRadius: 'lg',
    boxShadow: 'lg',
    width: 32,
  };
});

const baseStyleLabel = defineStyle(({ theme }) => {
  return {
    color: 'text.secondary',
    ...theme.textStyles.labelMediumRegular,
  };
});

const baseStyleLabelContainer = defineStyle(() => {
  return {
    pb: 2,
    pt: 3,
    px: 2,
  };
});

const baseStyleSwatch = defineStyle(() => {
  return {
    borderTopRadius: 'lg',
    height: 32,
    MozAppearance: 'none',
    WebkitAppearance: 'none',
    '&::-webkit-color-swatch': {
      border: 'none',
      background: 'transparent',
      opacity: 0,
    },
  };
});

const baseStyle = definePartsStyle((props) => {
  return {
    container: baseStyleContainer(),
    label: baseStyleLabel(props),
    labelContainer: baseStyleLabelContainer(),
    swatch: baseStyleSwatch(),
  };
});

export const colorSwatchTheme: ComponentStyleConfig = defineMultiStyleConfig({
  baseStyle,
});
