import { Schemas } from 'types/leap-api';
import { useStateValue } from 'providers';

export type UserRoles = Schemas['UserRole'][];

/**
 * Returns the full `roles` array from the User object.
 * Users may be assigned multiple roles. It is up to the consumer to determine
 * which role to use.
 * @returns {{ isCorporateUser, isRetailStoreAssocicate, UserRoles[] }}
 *
 *  "Admin: Owners"
 *  "Admin: Viewers"
 *  "Admin: Editors"
 *  "Brand: Editor"
 *  "Brand: Owner"
 *  "Corporate: Customer Success"
 *  "Retail: Market Manager"
 *  "Retail: Store Associate"
 *  "Retail: Store Leader"
 *
 */
export const useUserRoles = (): {
  isCorporateUser: boolean;
  isRetailStoreAssociate: boolean;
  userRoles: UserRoles;
} => {
  const [
    {
      session: { roles },
    },
  ] = useStateValue();

  const userRoles: UserRoles = roles ? roles : [];

  const corporateRoles = [
    'Admin: Owners',
    'Admin: Editors',
    'Admin: Viewers',
    'Corporate: Customer Success',
  ];

  return {
    isCorporateUser:
      userRoles.length > 0 &&
      userRoles.some((role) => corporateRoles.includes(role)),
    isRetailStoreAssociate:
      userRoles.length === 1 && userRoles[0] === 'Retail: Store Associate',
    userRoles,
  };
};
